<template>
  <a-modal
    title="附件"
    :width="620"
    :maskClosable="false"
    :keyboard="false"
    :closable="false"
    v-model:visible="visible"
    @cancel="handleCancel"
  >
    <div style="min-height: 200px">
      <p class="tips">
        {{ $t("bbs.addpost.file_tip", [fileList.length, 5], 1) }}
        <!-- 己上传{{ fileList.length }}个，最多上传5个 -->
        <!-- 支持pdf、ppt、pptx、doc、docx（单个文档小于100MB）、zip（单个附件小于1G） -->
      </p>
      <div class="file">
        <div class="file-add">
          <a-upload
            :action="uploadUrl + 'study/upload/file'"
            :headers="getHeader()"
            :multiple="true"
            accept=".pdf,.ppt,.pptx,.doc,.docx,.zip"
            v-model:file-list="fileList"
            :beforeUpload="handleBeforeUpload"
            @change="handleUploadChange"
          >
            <!-- <div class="up-input">
              <plus-outlined style="font-size: 18px" />
              <div class="up-input-text">上传文件</div>
            </div> -->
            <a-button>{{ $t("upload.upload_files") }}</a-button>
            <!-- 上传文件 -->
          </a-upload>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="custom-footer">
        <a-checkbox v-model:checked="isDown">{{
          $t("bbs.addpost.allow_download")
        }}</a-checkbox>
        <div class="btns">
          <a-button @click="handleCancel">{{ $t("CM_Cancel") }}</a-button>
          <!-- 取消 -->
          <a-button type="primary" :loading="loading" @click="handleOk">{{
            $t("CM_Confirm")
          }}</a-button>
          <!-- 确定 -->
        </div>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, toRefs, getCurrentInstance } from "vue";
import { uploadUrl, getHeader } from "@/utils/tools";

export default {
  name: "modalMedia",
  emits: ["handleOk"],
  setup(_, { emit }) {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const state = reactive({
      visible: false,
      loading: false,
      isDown: true,
      fileList: [],
    });

    const openModal = ({ isDown, files }) => {
      state.visible = true;
      state.loading = false;
      state.isDown = isDown;
      state.fileList = [];
      if (files.length) {
        files.forEach((item) => {
          state.fileList.push({
            uid: item.md5,
            name: item.name,
            url: item.file,
            status: "done",
            response: {
              data: {
                md5: item.md5,
                name: item.name,
                file: item.file,
                exist: item.exist,
              },
            },
          });
        });
      }
    };

    const handleBeforeUpload = (file) => {
      if (!file.size) {
        proxy.$message.error($t("upload.file_siez_cannot_be"));
        // 文件大小不能为0
        return false;
      }
      const isZip =
        file.type === "application/zip" ||
        file.type === "application/x-zip-compressed";
      const sizeFlag = file.size < 200 * 1024 * 1024;
      const zipSizeFlag = file.size < 1024 * 1024 * 1024;
      if (isZip) {
        if (!zipSizeFlag) {
          proxy.$message.error($t("upload.max_size_tip", ["1GB"]));
          // 文件大小不能超过1GB
          return sizeFlag;
        }
      } else if (!sizeFlag) {
        proxy.$message.error($t("upload.max_size_tip", ["200MB"]));
        // 文件大小不能超过200MB
        return sizeFlag;
      }
    };

    const handleUploadChange = (info) => {
      if (info.file.status === "uploading") {
        state.loading = true;
      }
      if (info.file.status === "done") {
        state.loading = false;
      }
      if (info.file.status === "error") {
        state.loading = false;
      }
    };

    const handleOk = () => {
      if (state.fileList.length) {
        if (state.fileList.length <= 5) {
          emit("handleOk", {
            isDown: state.isDown,
            files: state.fileList.map((item) => {
              let d = item.response.data;
              return {
                md5: d.md5,
                name: d.name,
                file: d.file,
                exist: d.exist,
              };
            }),
          });
          handleCancel();
        } else {
          proxy.$message.warn($t("upload.max_count_tip", [5]));
          // 最多上传5个附件
        }
      } else {
        proxy.$message.warn($t("bbs.addpost.please_upload_the_file"));
        // 请上传文件
      }
    };

    const handleCancel = () => {
      state.visible = false;
    };

    return {
      uploadUrl,
      getHeader,
      ...toRefs(state),
      openModal,
      handleBeforeUpload,
      handleUploadChange,
      handleOk,
      handleCancel,
    };
  },
};
</script>

<style lang="less" scoped>
.custom-footer {
  .mixinFlex(space-between; center);
}
.tips {
  color: #666;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 12px;
}
.file {
  margin-top: 12px;
  &-add {
    .up-input {
      border-radius: 4px;
      border: 2px dashed #ddd;
      width: 105px;
      height: 105px;
      .mixinFlex(center; center; column);
      color: #666;
      cursor: pointer;
      &-text {
        margin-top: 6px;
      }
    }
  }
}
</style>
